/* ==========================================================================
    header-anim-2
    ========================================================================== */

// ==== Variables hauteurs header

$height-header-anim-2-init: 10rem; // hauteur initial
$height-header-anim-2-affix: 5rem; // hauteur affix
// $height-navbar-ecommerce: 0; // si pas de navbar e-commerce
$height-navbar-ecommerce: 2rem; // si navbar e-commerce activee

// ==== Global

.header-anim-2 {
	position: relative;
	z-index: 1000;
	background-color: $navbar-default-bg;
	height: $navbar-height;
	display: flex;
	align-items: center;

	@media(min-width: $screen-md-min) {
		height: $height-header-anim-2-init + $height-navbar-ecommerce;
	}
}

// ==== Container logo

.header-anim-2__logo {
	position: absolute;
	z-index: 1100;
	top: 0;
	left: 0;
	max-width: 50%;
	height: 100%;
	padding: .5rem;

	@media(min-width: $screen-md-min) {
		position: relative;
		display: flex;
		justify-content: center;
		align-items: center;
		width: 20rem;
		height: $height-header-anim-2-init;
		transition: height ease .5s;
	}
}

// ==== Hauteur du logo

.header-anim-2__img-logo--animated {
	display: block;
	max-height: 100%;

	@media(min-width: $screen-md-min) {
		width: 100%;
		object-fit: contain;
		height: $height-header-anim-2-init - 2rem;
		transition: height .5s;
	}
}

// ==== Hauteur du block navbar

.header-anim-2__nav {
	width: 100%;
	height: $navbar-height;

	@media(min-width: $screen-md-min) {
		display: flex;
		align-items: center;
		height: $height-header-anim-2-init;
		transition: height ease .5s;
		padding-right: 2rem;
	}

	.navbar {
		width: 100%;
	}
}

// ==== Animations (affix)

.header-anim-2__inner {
	position: absolute;
	top: $height-navbar-ecommerce;
	left: 0;
	right: 0;
	background-color: $navbar-default-bg;

	&.affix {
		position: absolute;
	}
}

@media(min-width: $screen-md-min) {

	.header-anim-2__inner {
		position: fixed;
		height: $height-header-anim-2-init;
		transition: height ease .5s;

		&::after {
			content: '';
			position: fixed;
			top: 0;
			left: 0;
			right: 0;
			z-index: -1;
			background-color: $navbar-default-bg;
			height: $height-header-anim-2-init + $height-navbar-ecommerce;
			transition: height ease .5s;
		}

		&.affix {
			position: fixed;
			height: $height-header-anim-2-affix;

			&::after {
				height: $height-header-anim-2-affix;
			}

			.header-anim-2__nav {
				height: $height-header-anim-2-affix;
			}

			.header-anim-2__logo {
				height: $height-header-anim-2-affix;
			}

			.header-anim-2__img-logo--animated {
				height: 3.5rem;
			}
		}
	}
}
