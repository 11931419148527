/* ==========================================================================
  Liens sociaux
  ========================================================================== */

.link-fb {
  font-size: 1.2rem;
  .fa-circle {
    color: $brand-primary;
  }
}

.link-tw {
  font-size: 1.2rem;
  .fa-circle {
    color: $brand-primary;
  }
}

.link-pnt {
  font-size: 1.2rem;
  .fa-circle {
    color: $brand-primary;
  }
}

.link-linkedin {
  font-size: 1.2rem;
  .fa-circle {
    color: $brand-primary;
  }
}
